<template>
  <b-card
    title="เปลี่ยนรหัสผ่าน"
    class="form-change-password"
  >
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(fncSubmit)"
        @reset.prevent="fncResetData"
      >
        <b-row>
          <b-col cols="12">
            <b-row :class="`justify-content-md-center`">
              <b-col
                :cols="$store.state.app.windowWidth >= 1360 ? 8 : 12"
              >
                <b-form-group
                  label-cols-md="2"
                  label-cols-sm="3"
                  label-align-md="right"
                >
                  <template v-slot:label>
                    Username
                  </template>
                  <b-form-input
                    id="Username"
                    :value="userData.member_no"
                    trim
                    placeholder="Username"
                    autocomplete="off"
                    readonly
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row :class="`justify-content-md-center ${isLocalServer ? 'delete-margin' : ''}`">
              <b-col
                :cols="$store.state.app.windowWidth >= 1360 ? 8 : 12"
              >
                <b-form-group
                  label-cols-md="2"
                  label-cols-sm="3"
                  label-align-md="right"
                >
                  <template v-slot:label>
                    รหัสผ่านเดิม <span class="text-danger required-font-style">*</span>
                  </template>
                  <validation-provider
                    #default="validationContext"
                    name="passwordOld"
                    :rules="'required'"
                  >
                    <b-form-input
                      id="passwordOld"
                      v-model="passwordOld"
                      type="password"
                      trim
                      placeholder="Password"
                      autocomplete="off"
                      :state="getValidationState(validationContext)"
                    />
                    <small class="text-danger">
                      {{ validationContext.errors[0] ? 'กรุณากรอก Password' : '' }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row :class="`justify-content-md-center ${isLocalServer ? 'delete-margin' : ''}`">
              <b-col
                :cols="$store.state.app.windowWidth >= 1360 ? 8 : 12"
              >
                <b-form-group
                  label-cols-md="2"
                  label-cols-sm="3"
                  label-align-md="right"
                >
                  <template v-slot:label>
                    รหัสผ่านใหม่ <span class="text-danger required-font-style">*</span>
                  </template>
                  <validation-provider
                    #default="validationContext"
                    name="password"
                    :rules="'required'"
                  >
                    <b-form-input
                      id="password"
                      v-model="password"
                      type="password"
                      trim
                      placeholder="Password"
                      autocomplete="off"
                      :state="getValidationState(validationContext)"
                    />
                    <small class="text-danger">
                      {{ validationContext.errors[0] ? 'กรุณากรอก Password' : '' }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row :class="`justify-content-md-center ${isLocalServer ? 'delete-margin' : ''}`">
              <b-col
                :cols="$store.state.app.windowWidth >= 1360 ? 8 : 12"
              >
                <b-form-group
                  label-cols-md="2"
                  label-cols-sm="3"
                  label-align-md="right"
                >
                  <template v-slot:label>
                    ยืนยันรหัสผ่านใหม่ <span class="text-danger required-font-style">*</span>
                  </template>
                  <validation-provider
                    #default="validationContext"
                    name="passwordConfirm"
                    rules="required|confirmed:password"
                  >
                    <b-form-input
                      id="passwordConfirm"
                      v-model="passwordConfirm"
                      type="password"
                      trim
                      placeholder="Confirm Password"
                      autocomplete="off"
                      :state="getValidationState(validationContext)"
                    />
                    <small class="text-danger">
                      {{ !validationContext.errors[0] ? '' : validationContext.errors[0] == 'The passwordConfirm field is required' ? 'กรุณา กรอกรหัสผ่านใหม่' : 'รหัสผ่านยืนยันไม่ตรงกัน' }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row :class="`justify-content-md-center ${isLocalServer ? 'delete-margin' : ''}`">
              <b-col
                :cols="$store.state.app.windowWidth >= 1360 ? 8 : 12"
              >
                <b-form-group
                  label-cols-md="2"
                  label-cols-sm="3"
                  label-align-md="right"
                >
                  <!-- Form Actions -->
                  <div class="d-flex mt-2">
                    <b-overlay
                      :show="overlaySubmit"
                      rounded
                      opacity="0.6"
                      spinner-small
                      spinner-variant="primary"
                      class="d-inline-block"
                    >
                      <b-button
                        variant="primary"
                        class="mr-2"
                        type="submit"
                      >
                        <feather-icon icon="SaveIcon" />
                        บันทึกข้อมูล
                      </b-button>
                    </b-overlay>
                    <b-button
                      type="button"
                      variant="outline-secondary"
                      @click="fncResetData()"
                    >
                      ยกเลิก
                    </b-button>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BCard, BForm, BRow, BCol, BFormGroup, BFormInput, BOverlay, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'

import useChangePassword from './useChangePassword'

export default {
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BOverlay,
    BButton,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },

  setup() {
    const {
      // variable input
      passwordOld,
      password,
      passwordConfirm,

      // loading animation
      overlaySubmit,

      // function
      fncSubmit,
      fncResetData,

      // helper
      isLocalServer,
    } = useChangePassword()

    // vee validate
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    const userData = JSON.parse(localStorage.getItem('userData'))

    return {
      // variable input
      passwordOld,
      password,
      passwordConfirm,

      // loading animation
      overlaySubmit,

      // function
      fncSubmit,
      fncResetData,

      // helper
      isLocalServer,

      // vee validate
      refFormObserver,
      getValidationState,
      resetForm,
      required,

      // more
      userData,
    }
  },
}
</script>

<style>
.required-font-style
{
  font-weight: bold;
  font-size: 18px;;
}

.form-change-password .form-row > legend
{
  margin-top: 10px;
}

.form-change-password .delete-margin {
  margin-top: -40px;
}
</style>
