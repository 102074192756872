import { ref, computed } from '@vue/composition-api'
import axios from '@axios'
import router from '@/router'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useChangePassword() {
  // Notification
  const toast = useToast()

  // variable input
  const passwordOld = ref(null)
  const password = ref(null)
  const passwordConfirm = ref(null)

  // loading animation
  const overlaySubmit = ref(false)

  const fncResetData = () => {
    passwordOld.value = null
    password.value = null
    passwordConfirm.value = null
  }

  const fncSubmit = () => {
    overlaySubmit.value = true

    const payload = {
      password_old: passwordOld.value,
      password: password.value,
    }

    axios
      .put('/Member/Password', { requestData: payload })
      .then(response => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'แจ้งเตือน',
            icon: response.data.status ? 'CheckCircleIcon' : 'XCircleIcon',
            variant: response.data.status ? 'success' : 'warning',
            text: response.data.status ? 'เปลี่ยนรหัสผ่านสำเร็จ' : 'รหัสผ่านเดิมไม่ถูกต้อง',
          },
        })

        if (response.data.status) {
          fncResetData()
          router.push('/login')
        }

        overlaySubmit.value = false
      })
      .catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'แจ้งเตือน',
            text: `พบข้อผิดพลาดในการแก้ไขข้อมูล [${error}]`,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })

        overlaySubmit.value = false
      })
  }

  // helper
  const isLocalServer = computed(() => {
    const getServerName = window.location.host.includes(':') ? window.location.host.split(':')[0] : window.location.host
    return getServerName === 'localhost'
  })

  return {
    // variable input
    passwordOld,
    password,
    passwordConfirm,

    // loading animation
    overlaySubmit,

    // function
    fncSubmit,
    fncResetData,

    // helper
    isLocalServer,
  }
}
